
// INTERNAL MODULES
import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from "@angular/forms";

// DIRECTIVES
// import { PwdEqualityDirective, FileExtentionValidatorDirective, NumbersValidatorDirective, FileValidator, FileValueAccessor } from "../../_directives/index";

// PIPES
import { FilterPipe, OrderByPipe } from '../../_PIPES';

// EXTERNAL MODULES
import { NgxPaginationModule } from "ngx-pagination";
// import { TranslateModule } from '@ngx-translate/core';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { ModalModule } from 'ngx-bootstrap/modal';
import { ProgressbarModule } from 'ngx-bootstrap/progressbar';
// import { BsModalService } from "ngx-bootstrap/modal";
import { NgSelectModule } from '@ng-select/ng-select';

@NgModule({
  declarations: [
    FilterPipe,
    OrderByPipe,

    // PwdEqualityDirective,
    // FileExtentionValidatorDirective,
    // NumbersValidatorDirective,
    // FileValueAccessor,
    // FileValidator,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgSelectModule,
    // TranslateModule.forRoot(),
    // BsDatepickerModule.forRoot(),
    // ProgressbarModule.forRoot(),
  ],
  providers: [
    NgSelectModule,
    NgxPaginationModule,
    // TranslateModule,
    ModalModule,
    BsDatepickerModule,
    ProgressbarModule,

  ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgxPaginationModule,
    // TranslateModule,
    NgSelectModule,
    // ModalModule,
    // BsDatepickerModule,
    // ProgressbarModule,

    FilterPipe,
    OrderByPipe,

    // PwdEqualityDirective,
    // FileExtentionValidatorDirective,
    // NumbersValidatorDirective,
    // FileValueAccessor,
    // FileValidator,
  ]
})
export class SharedModule {
  static forRoot() {
    return {
      ngModule: SharedModule,
      providers: []
    };
  }
}
