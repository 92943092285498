import { Component, OnInit } from '@angular/core';
import { AuthenticationService, EnvService, SharedService } from "../../../_SERVICES";
import { TranslateService } from "@ngx-translate/core";
import { Router, NavigationEnd } from "@angular/router";

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.less']
})
export class HeaderComponent implements OnInit {
  lang: any = {active: localStorage.getItem('lang')};
  USER: any;
  env: any;

  constructor(
    private _AuthenticationService: AuthenticationService,
    private translate: TranslateService,
    private _SharedService: SharedService,
    private router: Router,
    private _Env: EnvService
  ) {
    this.env = this._Env;
    this.lang['active'] = this.lang['active'] === null ? 'en' : this.lang['active'];
    this.USER = JSON.parse(localStorage.getItem('currentUser'));
    this.router.events.subscribe((val) => {
      if (val instanceof NavigationEnd) {
        this.USER = JSON.parse(localStorage.getItem('currentUser'));
      }
    });
  }

  ngOnInit() {}

  public logout(): void {
    this._AuthenticationService.logout();
  }

  public useLanguage(lang): void {
    this.lang.active = lang;
    this.translate.use(lang);
    this._SharedService.changeLanguageEvent.emit(lang);
  }

}
