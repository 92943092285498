import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HTTP_INTERCEPTORS, HttpClientModule, HttpClient } from '@angular/common/http';
import { RouterModule } from "@angular/router";
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';


import { AppRoutingModule } from './app.routing';
import { AppComponent } from './app.component';
import { SharedModule } from "./SHARED/modules/shared.module";
import { LoginComponent } from './auth/login/login.component';
import { ErrorPageComponent } from './error-page/error-page.component';
import { FooterComponent } from './SHARED/components/footer/footer.component';
import { HeaderComponent } from './SHARED/components/header/header.component';
import { AlertComponent } from './SHARED/components/alert/alert.component';

// SERVICES
import { EnvServiceProvider, SharedService, AlertService, AuthenticationService, DispensersService } from './_SERVICES';

// GUARDS
import { AuthGuard } from './_GUARDS';

// INTERCEPTORS
import { JwtInterceptor, EncodeHttpParamsInterceptor } from './_HELPERS';

// EXTERNAL MODULES
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { defineLocale } from 'ngx-bootstrap/chronos';
import { enGbLocale } from 'ngx-bootstrap/locale';
import {NgxPaginationModule} from 'ngx-pagination'

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    ErrorPageComponent,
    FooterComponent,
    HeaderComponent,
    AlertComponent
  ],
  imports: [
    SharedModule.forRoot(),
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    RouterModule,
    BrowserAnimationsModule,
    NgxPaginationModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      }
    })
  ],
  providers: [
    EnvServiceProvider,
    AuthGuard,
    AuthenticationService,
    SharedService,
    AlertService,
    DispensersService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: JwtInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: EncodeHttpParamsInterceptor,
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
